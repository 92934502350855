<template>
  <div class="page">
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <FilterBlock
          :items="filters"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
        ></FilterBlock>
        <IconButton class="button--auto button--uppercase" text="add blog" @click="modal = true">
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <div class="page__filters">
      <template v-for="filter in selectedFilters"
        ><Tag
          class="tag--capitalize"
          v-for="i in filter.items"
          :key="i.title"
          :value="i.title"
          :close="true"
          :disabled="openFilters"
          @deleteTag="selectFilter({ key: filter.key, filter: i, apply: true })"
        ></Tag
      ></template>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table
    ><Blog
      v-if="modal"
      :modal="modal"
      :item="blog"
      @hideModal="modal = false"
      @createBlog="createBlog"
      @updateBlog="updateBlog"
      @removeBlog="confirmRemoveBlog"
      @updateTag="apiGetFiltersBlock(filterBlock)"
    ></Blog>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import FilterBlock from "@/components/Filter.vue";
import Tag from "@/components/Tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import Blog from "./Blog.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  components: {
    HorisontalTabsList,
    FilterBlock,
    IconButton,
    IconPlusBold,
    Table,
    Tag,
    Blog,
  },
  data() {
    return {
      tabs: [
        {
          title: "Blogs",
          path: "/admin/content/blogs",
          key: "blog",
        },
        {
          title: "Publications",
          path: "/admin/content/publications",
          key: "editorial",
        },
        {
          title: "Tag Manager",
          path: "/admin/content/tag-manager",
          key: "tags",
        },
        {
          title: "Pages",
          path: "/admin/content/pages",
          key: "pages",
        },
      ],
      modal: false,
      filterBlock: {
        list: "statuses",
        type: "content",
      },
      openFilters: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
      },
    };
  },
  async created() {
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetBlogs(this.tableData);
    this.initialBlog();
  },
  computed: {
    ...mapGetters("filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
    ...mapGetters("blogs", {
      items: "getBlogs",
      headerItems: "getBlogsHeaders",
      blog: "getBlog",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialBlog();
        this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("general", ["apiDeleteUploadImage"]),
    ...mapActions("filters", ["apiGetFiltersBlock"]),
    ...mapMutations("filters", ["updateFilters", "updateSelectedFilters", "clear"]),
    ...mapActions("blogs", [
      "apiGetBlogs",
      "apiGetBlog",
      "apiPostBlog",
      "apiPutBlog",
      "apiDeleteBlog",
    ]),
    ...mapMutations("blogs", ["initialBlog", "setError"]),
    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetBlogs(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetBlogs(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.tableData, page: 1 };
      this.selectedFilters.forEach(el => {
        let filters = el.items.map(i => {
          return i.id;
        });
        data[el.key] = filters.join(",");
      });
      let success = await this.apiGetBlogs(data);
      if (success) {
        this.tableData = data;
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetBlog(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmRemoveBlog(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
      }
    },

    async createBlog({ data, imageForRemove }) {
      let result = await this.apiPostBlog(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetBlogs(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Blog successfully created",
          confirmButtonText: "Ok",
        });
        /* if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        } */
      }
    },
    async updateBlog({ data, imageForRemove }) {
      let result = await this.apiPutBlog(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetBlogs(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Blog successfully updated",
          confirmButtonText: "Ok",
        });
        /* if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        } */
      }
    },
    confirmRemoveBlog(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeBlog(item.id);
        }
      });
    },
    async removeBlog(id) {
      let result = await this.apiDeleteBlog(id);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetBlogs(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$toasted.show(result.message, {
          duration: 2000,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        margin: 0 0 0 2rem;
      }
    }
  }
  &__filters {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 7px;
    .tag {
      margin-left: 1rem;
    }
  }
}
</style>
