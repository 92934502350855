<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="blog"
      id="scroll-block"
    >
      <header class="blog__header">
        <h3 class="blog__header_title">{{ blog.id ? "Edit" : "Add" }} Blog</h3>
        <div class="blog__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Blog"
            @click="$emit('removeBlog', blog)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid || imageValidateError.image || imageValidateError.preview_image"
            @click="saveBlog"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Blog Title"
              :required="true"
              :validateError="errors[0]"
              v-model="blog.title"
              :element="blog.title"
              @input="blog.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Author"
              :required="true"
              :validateError="errors[0]"
              v-model="blog.author"
              :element="blog.author"
              @input="blog.author = $event"
            ></BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Status"
              v-model="blog.is_published"
              type="status"
              :selected="blog.is_published"
              :items="[
                { key: '1', title: 'Published' },
                { key: '0', title: 'Draft' },
              ]"
              @action="blog.is_published = $event.key"
            ></BaseSelect>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="blog.slug"
              :element="blog.slug"
              @input="blog.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text"
                  >Example: {{ `${url}/library/blog/${blog.slug || "path"}` }}</span
                ></template
              >
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-tag"
            :loading="tagsLoading"
            :items="blog.tags"
            :matches="matches"
            placeholder="Tags"
            @addItem="addTag"
            @selectItem="blog.tags = $event"
            @searchMatches="apiGetMatchedTags"
          ></MultipleAutocompleteInput>
        </div>
        <div class="form__row">
          <div
            class="form__row_col--side form__row--label form__row--bordered form__row--justify-start pb-0"
            :class="{ 'form__row--error': imageValidateError.preview_image }"
          >
            <span class="form__row_label">
              Preview Picture <span class="form__row_label_required">*</span>
            </span>
            <UploadImage
              class="uploader--lg"
              size="15"
              :image="blog.preview_image"
              :loadingImage="loadingImage"
              id="preview_image"
              @upload="upload($event, 'preview_image')"
              @editImage="editImage($event, 'preview_image')"
              @removeImage="removeImage($event, 'preview_image')"
            ></UploadImage>
            <p class="form__row_error">{{ imageValidateError.preview_image }}</p>
          </div>
          <div
            class="form__row_col--side form__row--label form__row--bordered form__row--justify-start pb-0"
            :class="{ 'form__row--error': imageValidateError.image }"
          >
            <span class="form__row_label">
              Header Picture <span class="form__row_label_required">*</span>
            </span>
            <UploadImage
              class="uploader--lg"
              size="15"
              :image="blog.image"
              :loadingImage="loadingImage"
              id="image"
              @upload="upload($event, 'image')"
              @editImage="editImage($event, 'image')"
              @removeImage="removeImage($event, 'image')"
            ></UploadImage>
            <p class="form__row_error">{{ imageValidateError.image }}</p>
          </div>
        </div>
        <ValidationProvider
          tag="div"
          class="form__row form__row--mt-35"
          :rules="{ required: true }"
          v-slot="{ errors }"
        >
          <label class="form__row_label">
            Text Editor<span class="form__row_label_required">*</span>
          </label>
          <EditorNew
            v-model="blog.content"
            :validateError="errors[0]"
            :value="blog.content"
            @input="blog.content = $event"
          ></EditorNew>
        </ValidationProvider>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import UploadImage from "@/components/UploadImage.vue";
import EditorNew from "@/components/inputs/EditorNew.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseSelect,
    UploadImage,
    EditorNew,
    MultipleAutocompleteInput,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      blog: {
        title: "",
        author: "",
        slug: "",
        is_published: null,
        image: "",
        preview_image: "",
        content: "",
        tags: [],
      },
      imageValidateError: {
        preview_image: null,
        image: null,
      },
      removedImages: [],
      changed: false,
      loadingImage: false,
    };
  },
  created() {
    if (this.item != null) {
      this.blog = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("tags", {
      matches: "getMatchedTags",
      tagsLoading: "getChangesLoading",
    }),
    ...mapGetters("blogs", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    blog: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.is_published != null) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapActions("tags", ["apiPostTag", "apiGetMatchedTags"]),
    ...mapMutations("blogs", ["setError"]),
    async upload(file, key) {
      this.loadingImage = true;
      let image = await this.apiPostUploadImage(file);
      if (image) {
        this.blog[key] = image;
        this.imageValidateError[key] = null;
      }
      this.loadingImage = false;
    },
    async editImage({ el, idx }, key) {
      this.loadingImage = true;
      this.removedImages.push(this.blog[key]);
      let image = await this.apiPostUploadImage(el);
      if (image) {
        this.blog[key] = image;
      }
      this.loadingImage = false;
    },
    async removeImage(idx, key) {
      this.loadingImage = true;
      this.imageValidateError[key] = "This field is required";
      this.removedImages.push(this.blog[key]);
      this.blog[key] = "";
      this.loadingImage = false;
    },
    async addTag(val) {
      let data = { title: val };
      let result = await this.apiPostTag(data);
      if (result.success) {
        this.blog.tags.push(result.tag);
        this.$emit("updateTag");
      } else {
        this.$toasted.show(result.error, {
          duration: 2000,
          type: "error",
        });
      }
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid && imageValidateError == null
              ? this.saveBlog()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveBlog() {
      let data = {
        ...this.blog,
        tags: this.blog.tags.map(el => {
          return el.id.toString();
        }),
      };
      if (this.blog.id) {
        this.$emit("updateBlog", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createBlog", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.blog {
  height: 100%;
  //padding: 0 15px;
  //overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
